import { TriliaAppMode, TriliaAppDownloadURL } from "./Config";

const LightMenu = [
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΠΛΗΡΩΜΩΝ",
    icon: "euro-sign",
    entries: [
      {
        name: "Με μια ματιά",
        icon: "eye",
        path: "/dashboard",
        adminOnly: false,
      },
      { name: "Ανά κατηγορία", icon: "sitemap", path: "/tr/categories" },
      {
        name: "Αναλυτικά",
        icon: "money-bill",
        path: "/tr/detailed",
        adminOnly: false,
      },
    ],
  },
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΚΑΡΤΟΚΙΝΗΤΗΣ",
    icon: "sim-card",
    entries: [
      {
        name: "Αναλυτικά",
        icon: "money-bill",
        path: "/tr/detailed/posit",
        adminOnly: false,
      },
    ],
  },
  {
    name: "ΠΡΟΣΘΕΤΟ ΥΛΙΚΟ",
    icon: "cloud",
    entries: [
      {
        name: "Διάφορα έγγραφα",
        icon: "download",
        path: "/downloads",
        adminOnly: false,
      },
      {
        name: "Λήψη εφαρμογής",
        icon: "download",
        path: TriliaAppDownloadURL,
        adminOnly: false,
        external: true,
      },
    ],
  },
];

const FullMenu = [
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΠΛΗΡΩΜΩΝ",
    icon: "euro-sign",
    entries: [
      {
        name: "Με μια ματιά",
        icon: "eye",
        path: "/dashboard",
        adminOnly: false,
      },
      { name: "Συνολικά", icon: "money-bill", path: "/tr/total" },
      { name: "Αναλυτικά", icon: "money-bill", path: "/tr/detailed" },
      { name: "Ανά κατηγορία", icon: "sitemap", path: "/tr/categories" },
      { name: "Ανά τρόπο πληρωμής", icon: "credit-card", path: "/tr/pm" },
      {
        name: "Ανά αντιπρόσωπο",
        icon: "user",
        path: "/tr/dealers",
        adminOnly: true,
      },
    ],
  },
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΚΑΡΤΟΚΙΝΗΤΗΣ",
    icon: "sim-card",
    entries: [
      {
        name: "Με μια ματιά",
        icon: "eye",
        path: "/dashboard/posit",
        adminOnly: true,
      },
      { name: "Συνολικά", icon: "money-bill", path: "/tr/total/posit" },
      { name: "Αναλυτικά", icon: "money-bill", path: "/tr/detailed/posit" },
      {
        name: "Ανά αντιπρόσωπο",
        icon: "user",
        path: "/tr/dealers/posit",
        adminOnly: true,
      },
    ],
  },
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΑΝΑΛΗΨΕΩΝ",
    icon: "hand-holding-usd",
    entries: [
      {
        name: "Συνολικά",
        icon: "money-bill",
        path: "/notavailable",
        nohighlight: true,
      },
      {
        name: "Ανά αντιπρόσωπο",
        icon: "user",
        path: "/notavailable",
        nohighlight: true,
      },
    ],
  },
  {
    name: "ΣΥΝΑΛΛΑΓΕΣ ΚΑΤΑΘΕΣΕΩΝ",
    icon: "hand-holding-usd",
    entries: [
      {
        name: "Συνολικά",
        icon: "money-bill",
        path: "/notavailable",
        nohighlight: true,
      },
      {
        name: "Ανά αντιπρόσωπο",
        icon: "user",
        path: "/notavailable",
        nohighlight: true,
      },
    ],
  },
];

export const SiteMenu = TriliaAppMode ? LightMenu : FullMenu;
