import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../Store";
import { TriliaAppMode, TriliaAppDatabase } from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortObjectArray, dateToString } from "../../../utils";
import { Button, Container, Table } from "reactstrap";
import Header from "./header";
import Row from "./row";
import Cards from "./Cards";
import Pagination from "../../Pagination/Pagination";
import { usePagination } from "../../Pagination/usePagination";
import { exportExcel } from "./exporter";
import Spinner from "../../../Spinner";
// import LineTotals from "../../charts/LineTotals";
import Filters from "../../filter/Filters";
import { useApiCall } from "../../../useApiCall";
import "../transactions.css";

function List({ posit = false }) {
  const [state] = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedField, setSortedField] = useState("");
  const [transactions, setTransactions] = useState(null);
  const [originalTransactions, setOriginalTransactions] = useState(null);
  const pagination = usePagination({
    currentPage,
    pageSize: state.pageSize,
    records: transactions?.records,
  });
  const getDetailedTransactions = useApiCall("/detailed_transactions");

  const sort = (key, asc) => {
    setSortedField(key);
    setTransactions({
      ...transactions,
      records: sortObjectArray(transactions?.records, key, asc),
    });
  };

  const filterList = (category, org, method, status, bank, device, clrRef) => {
    // console.log(category, method, status, clrRef)
    const matches = originalTransactions?.records?.filter((item) => {
      return (
        (!org ||
          org === "" ||
          item.organization
            .toLocaleLowerCase()
            .includes(org.toLocaleLowerCase())) &&
        (!category || category === "" || item.category === category) &&
        (!method || method === "" || item.method === method) &&
        (!status || status === "" || item.status === status) &&
        (!bank || bank === "" || item.bank === bank) &&
        (!device || device === "" || item.serialNo === device) &&
        (!clrRef ||
          clrRef === "" ||
          item.clearingRef
            .toLocaleLowerCase()
            .includes(clrRef.toLocaleLowerCase()))
      );
    });
    const stats = { ...originalTransactions?.statistics };
    if (matches.length !== originalTransactions?.records?.length) {
      // filtered
      stats.transactionsSum = matches.length;
      stats.amountSum = matches.reduce((total, item) => total + item.amount, 0);
      stats.commissionSum = matches.reduce(
        (total, item) => total + item.commission,
        0,
      );
    }
    // console.log("count", matches.length, ", sum", sum, ", commission", com);
    setTransactions({
      ...originalTransactions,
      statistics: stats,
      records: matches,
    });
  };

  useEffect(() => {
    const abort = new AbortController();

    setTransactions(null);
    getDetailedTransactions(
      JSON.stringify(
        {
          ...state.filters,
          user: { userIds: state.userIds },
          database: TriliaAppMode ? TriliaAppDatabase : state.database,
          bank: state.bank,
          posit: posit,
        },
        "",
        { signal: abort.signal },
      ),
    ).then((resp) => {
      setTransactions(resp);
      setOriginalTransactions(resp);
    });

    return () => abort.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setOriginalTransactions,
    setTransactions,
    state.filters,
    state.userIds,
    state.access_token,
    state.database,
    state.bank,
    state.pageSize,
    posit,
  ]);

  return (
    <Container fluid>
      <h2 className="page-header">
        Συναλλαγές {posit ? "καρτοκινητής" : "πληρωμών"}
      </h2>

      <Cards transactions={transactions} />

      <Filters
        statistics={transactions?.statistics}
        datesVisible={true}
        devicesVisible={true}
        transactionsVisible={false}
        locationVisible={false}
        searchVisible={false}
        autoClose={true}
        defaultOpen={false}
      />

      {!transactions && <Spinner />}
      {transactions && !transactions.records && (
        <Spinner message="Δεν υπάρχουν εγγραφές" showSpinner={false} />
      )}

      <TableList
        sortedField={sortedField}
        filterList={filterList}
        sort={sort}
        originalTransactions={originalTransactions}
        transactions={transactions}
        paginatedRecords={pagination?.records}
        state={state}
      />

      <Pagination
        pagination={pagination}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </Container>
  );
}

const TableList = ({
  sortedField,
  filterList,
  sort,
  originalTransactions,
  transactions,
  paginatedRecords,
  state,
}) => {
  if (!transactions?.records) {
    return <></>;
  }

  const allDevices = [
    ...new Set(originalTransactions?.records?.map((v) => v.serialNo)),
  ];

  const allBanks = [
    ...new Set(originalTransactions?.records?.map((v) => v.bank)),
  ];

  return (
    <>
      {/* <LineTotals transactions={transactions} /> */}

      <Table size="sm" striped hover responsive>
        <Header
          sortedField={sortedField}
          doSort={sort}
          onChanged={filterList}
          devices={allDevices}
          banks={allBanks}
          statuses={transactions?.statistics?.statuses}
        />
        {transactions?.records?.length > 0 && (
          <tbody>
            {paginatedRecords?.map((trans) => {
              return <Row row={trans} key={`${trans.id}`} />;
            })}
          </tbody>
        )}
      </Table>

      <div className="exporters-container">
        <Button
          size="sm"
          onClick={() =>
            exportExcel(
              `Transactions_${dateToString(new Date(transactions?.statistics?.date?.start))}-${dateToString(new Date(transactions?.statistics?.date?.end))}.xlsx`,
              state,
              transactions?.statistics,
              transactions?.records,
            )
          }
        >
          <FontAwesomeIcon icon="save" /> Εξαγωγή σε Excel
        </Button>
      </div>
    </>
  );
};
export default List;
